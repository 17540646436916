@import "../sass/utils.scss";

.ScheduleSidebar {
  border-top: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: 0.3s;
  overflow: auto;
  .ScheduleSidebarContent {
    width: 100%;
    .SearchBar {
      color: #3c4043;
      font-size: 14px;
      font-family: "Sniglet", Cursive;
      width: 100%;
      padding: 10px 10px;
      border-radius: 8px;
      border: 1px solid #f1f3f5;
      background-color: #f1f3f5;
      outline: none;
      box-sizing: border-box;
      margin-bottom: 25px;
    }
    .SearchBar:focus {
      border-bottom: 2px solid #ffc114;
    }
    .SectionHeading {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 16px;
      font-family: "Sniglet", Cursive;
      margin-bottom: 10px;
      > svg {
        font-size: 14px;
      }
    }
    .DetailedViewButtons {
      display: flex;
      justify-content: space-between;
      gap: 0.3em;
      @media (max-width: 1720px) {
        flex-direction: column;
      }
      button {
        width: 100%;
        cursor: pointer;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        padding: 5px 10px;
        font-family: "Sniglet", cursive;
        font-size: 14px;
        transition: 0.3s;
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: center;
      }
    }
  }
}
