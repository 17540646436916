@import "../sass/reset.scss";
@import "../sass/utils.scss";

.Schedule {
  width: 100%;
  height: max-content;
  font-family: $general-font;

  > .ScheduleBody {
    display: flex;
    width: 100%;
    height: 92vh;
  }
}
