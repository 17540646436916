// vars
// Colors

//### CREATE TEACHER COLORS VARS ###

// Font
$general-font: "Noto Sans", sans-serif;
$cursive-font: "Sniglet", "cursive";

// Breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@mixin animation($property, $value) {
  -webkit-animation-#{$property}: $value;
  animation-#{$property}: $value;
}

@mixin transform($value) {
  -webkit-transform: $value;
  -ms-transform: $value;
  transform: $value;
}

@mixin transition-duration($value) {
  -webkit-transition-duration: $value;
  -o-transition-duration: $value;
  transition-duration: $value;
}

//*********//
// FLEXBOX //
//*********//

@mixin flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@mixin flex-col {
  @include flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@mixin flex-col-reverse {
  @include flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
@mixin flex-row {
  @include flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
@mixin flex-row-reverse {
  @include flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@mixin flex-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@mixin flex-justify-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@mixin flex-justify-space-around {
  -webkit-box-pack: distribute;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
@mixin flex-justify-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@mixin flex-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@mixin flex-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@mixin flex-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@mixin flex-align-space-around {
  -webkit-box-align: distribute;
  -ms-flex-align: distribute;
  align-items: space-around;
}
@mixin flex-align-space-between {
  -webkit-box-align: justify;
  -ms-flex-align: justify;
  align-items: space-between;
}
@mixin flex-align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@mixin flex-align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
@mixin flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
@mixin flex-nowrap {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@mixin flex-center {
  @include flex-justify-center;
  @include flex-align-center;
}
@mixin flex-align-self-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}
@mixin flex-align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
@mixin flex-order($value) {
  -webkit-box-ordinal-group: $value + 1;
  -ms-flex-order: $value;
  order: $value;
}
@mixin flex-grow($value) {
  -webkit-box-flex: $value;
  -ms-flex-positive: $value;
  flex-grow: $value;
}
@mixin flex-shrink($value) {
  -ms-flex-negative: $value;
  flex-shrink: $value;
}
@mixin flex-basis($value) {
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}
@mixin flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

//*****************//
//** BREAKPOINTS **//
//*****************//

@mixin media-breakpoint-up($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}
@mixin media-breakpoint-down($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

/*# sourceMappingURL=utils.css.map */
.spinner-loader {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* transform: rotate(3deg); */
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ScheduleModal {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  margin: auto;
  z-index: 100;
  // background-color: rgba(0, 0, 0, 0.5);
  .ScheduleModalContent {
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    // max-width: max-content;
    height: max-content;
    border-radius: 8px;
    min-width: max-content;
    width: 33%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    @media (max-width: 1200px) {
      width: 80%;
    }
    .ScheduleModalForm {
      display: flex;
      flex-direction: column;
      gap: 7px;
      width: 100%;
      textarea,
      input,
      select {
        border-radius: 5px;
        border: 1px solid #dadce0;
        outline: none;
        padding: 7px;
        font-size: 14px;
        box-sizing: border-box;
        width: 100%;
      }
      input[type="color"] {
        padding: 2px;
        height: 35px;
      }
      label {
        font-size: 12px;
        color: #70757a;
        font-weight: bold;
      }
      .ScheduleModalInputs {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1em;
      }
      .ScheduleModalInput {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
      }
      .ScheduleModalCheckBoxIcons {
        color: #263238;
        font-size: 14px;
      }
      .ScheduleModalCheckboxGroup {
        padding: 0 10px;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        gap: 10px;
        .ScheduleModalCheckbox {
          display: flex;
          gap: 2px;
          align-items: center;
        }
      }
    }
    .ScheduleModalHead {
      background-color: #e8f0fe;
      color: #5f6368;
      border: 1px solid #f1f3f4;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      font-size: 18px;
      font-weight: bold;
      font-family: "Sniglet", cursive;
      padding: 12px;
      display: flex;
      justify-content: space-between;
    }
    .ScheduleModalBody {
      display: flex;
      justify-content: space-between;
      padding: 15px;
    }
    .ScheduleModalFoot {
      display: flex;
      justify-content: space-between;
      padding: 7px;
      align-items: center;
      background-color: #e8f0fe;
      .ScheduleModalActions {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        width: max-content;
        button {
          cursor: pointer;
          background-color: #ffffff;
          border: 1px solid #dadce0;
          border-radius: 10px;
          padding: 7px 12px;
          font-weight: bold;
          border-radius: 50px;
          color: #5f6368;
        }
        button:hover {
          color: #000000;
        }
        .ConfirmActionButton {
          background-color: #1a73e8;
          color: #ffffff;
          border: 1px solid #1a73e8;
        }
        .ConfirmActionButton:hover {
          background-color: #1c65c8;
        }
      }
    }
    .ScheduleModalError {
      color: #d87093;
      font-size: 13px;
    }
  }
}

.react-calendar {
  border: none !important;
}
