@import "../sass/utils.scss";

.ScheduleClassDetails {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  @include flex;
  @include flex-center;

  > .details {
    border-radius: 10px;
    font-size: 80%;
    overflow: hidden;

    @include media-breakpoint-up(425px) {
      font-size: 100%;
      min-width: 415px;
    }

    > header {
      @include flex-row;
      @include flex-justify-space-between;
      @include flex-align-center;
      background-color: rgba(0, 0, 0, 0.05);
      font-size: 175%;
      padding: 15px 20px;
      gap: 50px;
      > h2 {
        font-family: $cursive-font;
        text-align: left;
        white-space: nowrap;
        @include flex-row;

        > .alert {
          font-size: 80%;
          font-family: $general-font;
          height: fit-content;
          margin-left: 0.5em;
          font-weight: bold;
          @include flex;
          @include flex-center;

          > div {
            border-radius: 2px;
            background-color: #ffc114;
            padding: 4px;
            border-radius: 10px;
            height: fit-content;
            white-space: nowrap;
            font-size: 90%;
            @include flex;
            @include flex-center;
            > img {
              height: 1em;
              margin-right: 0.25em;
            }
          }
        }
      }
      > button {
        font-size: 120%;
        border: none;
        background-color: transparent;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
    > main {
      font-size: 125%;
      padding: 25px;

      > .schedule,
      .teacher,
      .online-onsite {
        @include flex;
        @include flex-center;
        margin-bottom: 20px;

        > img {
          height: 2em;
          margin-right: 0.5em;
        }
        &.online-onsite > img {
          height: 1.5em;
        }

        > .grouped > div {
          text-align: center;
        }
      }

      > .term {
        width: 100%;

        > .title {
          font-weight: bold;
          width: 100%;
          text-align: center;
        }
        > .info {
          width: 100%;
          @include flex-row;
          text-align: center;
          margin-top: 10px;

          > .box {
            width: calc(100% / 3);
            @include flex-col;
            @include flex-align-center;
            padding-left: 5px;
            padding-right: 5px;
            gap: 10px;

            &:not(:last-child) {
              border-right: 1px solid black;
            }

            > .from-to {
              font-size: 90%;
            }
            > .date {
              @include flex-center;
              font-family: $cursive-font;
              font-size: 120%;
              display: flex;
              gap: 10px;
              > span > .suffix {
                font-size: 80%;
              }
            }
            > .count {
              font-family: $cursive-font;
              font-size: 120%;
            }
            > .text {
              font-family: $cursive-font;
            }
          }
        }
      }
      > .price {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 10px;
        @include flex-row;
        border-top: 1px solid black;

        > div {
          @include flex-col;
          @include flex-align-center;
          gap: 10px;
          &:not(:last-child) {
            border-right: 1px solid black;
          }
          &.hourly-rate {
            width: calc(100% / 3);
          }
          &.total {
            width: calc((100% / 3) * 2);
          }
          > .amount {
            font-family: $cursive-font;
            font-size: 120%;
          }
          > .tax-inc {
            font-size: 80%;
          }
        }
      }
      > .exceptions {
        width: 100%;
        margin-bottom: 10px;
        padding-top: 10px;
        border-top: 1px solid black;
        > div {
          font-family: $cursive-font;
        }
        > ul {
          width: 100%;
          > li {
            margin-left: 2em;
          }
        }
      }
    }
  }
}
