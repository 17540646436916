.ClassManager {
  .CreateButtonContainer {
    position: relative;
    .CreateButton {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 15px 20px;
      width: fit-content;
      font-size: 100%;
      font-family: "Sniglet", Cursive;
      border: transparent;
      border-radius: 50px;
      box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3),
        0px 1px 3px 1px rgba(60, 64, 67, 0.15);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      outline: none;
      color: #3c4044;
      margin-bottom: 25px;
    }
    .CreateButton:hover {
      background-color: #ffc114;
      color: #fff;
      transform: translateY(-7px);
      > span {
        > svg {
          color: #fff;
        }
      }
    }
    .CreateButtonGroup {
      position: absolute;
      top: -10px;
      left: 130px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      z-index: 9999999999;
      button {
        border-radius: 50px;
        box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3),
          0px 1px 3px 1px rgba(60, 64, 67, 0.15);
        font-family: "Sniglet", Cursive;
        border: transparent;
        color: #3c4044;
        background-color: #ffffff;
        padding: 5px 10px;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        &:hover {
          background-color: #ffc114;
          color: #fff;
        }
      }
    }
  }

  .ClassManagerError {
    color: #d87093;
    font-size: 13px;
  }
  #ClassForm {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    textarea,
    input,
    select {
      border-radius: 5px;
      border: 1px solid #dadce0;
      outline: none;
      padding: 7px;
      font-size: 14px;
      box-sizing: border-box;
      width: 100%;
    }
    label {
      font-size: 12px;
      color: #70757a;
      font-weight: bold;
    }
    .ClassTimeInputs {
      display: flex;
      justify-content: space-between;
      gap: 1em;
    }
    .ClassFormInput {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      .ClassGradesSelector {
        max-height: 70px;
        overflow: auto;
        border: 1px solid #dadce0;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 7px;
        label {
          padding: 3px 0;
        }
      }
    }
    .CheckBoxIcons {
      color: #263238;
      font-size: 14px;
    }
    .ClassCheckboxGroup {
      padding: 0 10px;
      display: grid;
      grid-template-columns: 20% 20% 20% 20% 20%;
      gap: 10px;
      .ClassCheckbox {
        display: flex;
        gap: 2px;
        align-items: center;
      }
    }
  }
}
