@import "../sass/utils.scss";

.ScheduleView {
  @include flex-row;
  // height: calc(95% - 1px);
  // height: 100%;
  overflow: visible;
  // border-top: 1px solid rgb(226, 226, 226);

  > .view {
    @include flex-row;
    width: 100%;
    overflow: auto;
    border-top: 1px solid #e2e2e2;

    > div {
      border-left: 1px solid #d8d8d8;
    }
  }
  .ScheduleNoClass {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.spinner-loader-container {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  height: max-content;
  width: max-content;
}
