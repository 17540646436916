.FilterList {
  padding: 10px;
  font-family: "Sniglet", Cursive;
  label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .TeacherListItem {
    padding: 10px;
    font-size: 14px;
  }
  .TextGoldenYellow {
    background-color: #eeea00;
  }
  .TextTurquoise {
    background-color: #afeeee;
    padding: 10px;
    font-size: 14px;
  }
  .TextPaleGolden {
    background-color: #eee8aa;
    padding: 10px;
    font-size: 14px;
  }
  .TextVioletRed {
    background-color: #d87093;
    padding: 10px;
    font-size: 14px;
  }
  .TextPerfume {
    background-color: #cdafee;
    padding: 10px;
    font-size: 14px;
  }
  .ItemDeleteIcon {
    box-shadow: 41px 41px 82px #e3e3e3, -41px -41px 82px #ffffff;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
}
