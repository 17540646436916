.GradesFilterList {
  font-family: "Sniglet", Cursive;
  li {
    border-bottom: 1px solid #e2e2e2;
    label {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 11px 19px;
    }
  }
}
