@import "../sass/utils.scss";

.ScheduleDay {
  position: relative;
  @include flex-grow(1);
  min-width: 390px;
  height: 100%;
  overflow: visible;

  > h2 {
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
    font-family: $cursive-font;
    padding: 10px 0;
  }
}
