.ScheduleAlert {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  width: max-content;
  z-index: 9999999999;
  padding: 10px;
  border-radius: 10px;
  transition: 0.3s;
}
