.DatePickerContainer {
  margin-bottom: 25px;
  .DatePicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3c4043;
    font-size: 14px;
    font-family: "Sniglet", Cursive;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #f1f3f5;
    background-color: #f1f3f5;
    outline: none;
    box-sizing: border-box;
    overflow: hidden;
    @media (max-width: 1700px) {
      flex-direction: column;
      align-items: flex-start;
      .DatePickerInputGroup {
        display: flex;
        justify-content: space-between;
        width: 100% !important;
        box-sizing: border-box;
        align-items: center;
        .react-datepicker-wrapper {
          width: 40%;
        }
      }
      .DatePickerButton {
        height: max-content !important;
        width: 100% !important;
        border-radius: 0px !important;
        display: flex;
        justify-content: center;
        z-index: 0 !important;
        &:active {
          transform: scale(1.1) !important;
        }
      }
    }
    @media (max-width: 1250px) {
      .DatePickerButton {
        z-index: 0 !important;
      }
      .DatePickerInputGroup {
        width: 100% !important;
        justify-content: center;
        input {
          width: 100%;
        }
        label {
          display: none !important;
        }
        .react-datepicker-wrapper {
          width: 100%;
        }
      }
    }
    input {
      border: none;
      outline: none;
      background: transparent;
    }
    .DatePickerInputGroup {
      padding: 10px 10px;
      width: 35%;
      label {
        font-weight: 600;
      }
    }
    .DatePickerButton {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px;
      width: max-content;
      height: 51.33px;
      font-size: 100%;
      font-family: "Sniglet", Cursive;
      border: transparent;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      transition: all 0.3s ease 0s;
      cursor: pointer;
      outline: none;
      background: #ffc114;
      color: #fff;
      position: relative;
      z-index: 999;
      &:hover {
        background-color: #ffc114;
        color: #fff;
      }
      &:active {
        transform: translateX(5px);
      }
    }
  }
  .DatePickerHideEmpty {
    font-size: 14px;
    margin-top: 5px;
    label {
      display: flex;
      gap: 0.3em;
      align-items: center;
    }
  }
}
