.ScheduleTable {
  width: 100%;
  overflow: auto;
  table {
    width: 100%;
    thead {
      border-bottom: 1px solid #d5d5d5;
    }
    tr {
      word-spacing: normal;
      cursor: pointer;
      transition: 0.3s;
      th,
      td {
        padding: 15px;
        text-align: left;
        white-space: nowrap;
        border-right: 1px solid #d5d5d5;
        border-bottom: 1px solid #d5d5d5;
        transition: 0.3s;
      }
    }
    // .ScheduleTableRows {
    //   &:hover {
    //     transform: translate(14px);
    //   }
    // }
  }
  .ScheduleTableActions {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    .ScheduleTableBtn {
      max-height: 95%;
      background-color: #f1f1f1;
      border: 1px solid #d5d5d5;
      border-bottom-color: #e2e2e2;
      border-radius: 8px;
      cursor: pointer;
      transform: translateY(1px);
      font-size: 85%;
      padding: 8px 10px;
      display: flex;
      flex-direction: row;
      font-family: "Sniglet", cursive;
      transition: 0.3s;
      &:hover {
        background: black;
        color: #fff;
      }
    }
  }
}
