@import "../sass/utils.scss";

.ScheduleHeader {
  @include flex-row;
  justify-content: space-between;
  padding: 0 25px;
  height: 4.4em;
  align-items: center;

  > h1 {
    @include flex-row;
    @include flex-align-center;
    font-family: $cursive-font;
    font-size: 160%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 20%;
    gap: 5px;
    align-items: center;

    > img {
      height: 30px;
      width: 30px;
    }
    > span {
      display: none;
    }

    @include media-breakpoint-up($sm) {
      > span {
        display: block;
      }
    }
  }
  > .tabs {
    @include flex-row;
    @include flex-align-center;
    margin: 0 20px;
    gap: 12px;

    > button {
      max-height: 95%;
      background-color: rgb(241, 241, 241);
      border: 1px solid rgb(213, 213, 213);
      border-bottom-color: rgb(226, 226, 226);
      border-radius: 8px;
      // border-top-left-radius: 10px;
      // border-top-right-radius: 10px;
      cursor: pointer;
      @include transform(translateY(1px));
      font-size: 85%;
      padding: 8px 10px;
      @include flex-row;
      font-family: "Sniglet", cursive;

      &.active {
        background-color: white;
        border-color: rgb(226, 226, 226);
        // border-bottom-color: white;
        cursor: default;
        color: #edae00;
      }

      @include media-breakpoint-up($sm) {
        font-size: 100%;
      }
    }
  }
  .ScheduleHeaderActions {
    display: flex;
    align-items: center;
    gap: 5px;
    width: max-content;
    .SchduleTableCalendarView {
      button {
        cursor: pointer;
        background-color: white;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        padding: 5px 10px;
        font-family: "Sniglet", cursive;
        font-size: 14px;
        transition: 0.3s;
        width: 130px;
        &:hover {
          background: #f1f1f1;
        }
        div {
          display: flex;
          align-items: center;
          gap: 6px;
          justify-content: space-between;
        }
      }
    }
  }
}
