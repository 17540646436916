.ScheduleDropdownContainer {
  width: 100%;
  position: relative;
  .ScheduleDropdownTrigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dadce0;
    outline: none;
    padding: 7px;
    font-size: 14px;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
  }
  .ScheduleDropdown {
    border-radius: 5px;
    border: 1px solid #dadce0;
    outline: none;
    font-size: 14px;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    top: 30px;
    max-height: 300px;
    overflow: auto;
    box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3),
      0px 1px 3px 1px rgba(60, 64, 67, 0.15);
    .ScheduleDropdownItem {
      padding: 7px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background: #f1f2f3;
      }
    }
  }
  .ScheduleDropdownBoldText {
    font-weight: 600;
  }
}
