.Collapsible {
  background-color: #f1f3f5;
  border-radius: 8px;
  margin-bottom: 20px;
  > .CollapseBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-family: "Sniglet", Cursive;
    padding: 10px;
    cursor: pointer;
    > .ActionButton {
      display: flex;
      gap: 15px;
      align-items: center;
      cursor: pointer;
      > .CollapseArrow {
        > svg {
          color: #3c4043;
        }
      }
      > .CollapsePlus {
        > svg {
          color: #3c4043;
        }
      }
    }
  }
  .CollapseBar:hover {
    background-color: #f1f3f5;
  }
  .CollapseDropdown {
    background-color: #f1f3f5;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: max-height 0.15s ease-out 0s;
    overflow: auto;
  }
}
