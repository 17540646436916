.ScheduleLoginAction {
  button {
    cursor: pointer;
    background-color: white;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 5px 10px;
    font-family: "Sniglet", cursive;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: 0.3s;
    &:hover {
      background: #f1f1f1;
    }
  }
  .ScheduleForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    .ScheduleFormInput {
      display: flex;
      flex-direction: column;
      gap: 5px;
      input {
        border-radius: 5px;
        border: 1px solid #dadce0;
        outline: none;
        padding: 7px;
        font-size: 14px;
      }
      label {
        font-size: 12px;
        color: #70757a;
        font-weight: bold;
      }
    }
  }
  .ScheduleFormError {
    color: #d87093;
    font-size: 13px;
  }
  .ScheduleModalFoot {
    .ConfirmActionButton {
      &:hover {
        color: #fff !important;
      }
    }
  }
}
