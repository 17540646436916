@import "../sass/utils.scss";

.ScheduleClass {
  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  font-size: min(2vh, 100%);

  text-align: center;

  > .start,
  > .end {
    height: 1.5em;
    font-weight: bold;
    @include flex-row;
    @include flex-center;
    background-color: rgba(0, 0, 0, 0.05);

    position: absolute;
    width: 100%;
    left: 0;

    &.start {
      top: 0;
      > .alert {
        background-color: #ffc114;
        padding: 4px;
        border-radius: 10px;
        margin-left: 0.2em;
        > img {
          height: 1em;
        }
      }
    }
    &.end {
      bottom: 0;
    }
  }
  > .alert {
    width: 100%;
    height: fit-content;
    margin-top: 1.5em;
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: bold;
    @include flex;
    @include flex-center;

    > div {
      border-radius: 2px;
      background-color: #ffc114;
      padding: 4px;
      border-radius: 10px;
      height: fit-content;
      white-space: nowrap;
      font-size: 90%;
      @include flex;
      @include flex-center;
      > img {
        height: 1em;
        margin-right: 0.25em;
      }
    }
  }
  > .info {
    height: calc(100% - 3em);
    @include flex-col;
    @include flex-center;
    overflow: hidden;
    font-family: $cursive-font;
    position: relative;

    margin-top: 1.5em;
    margin-bottom: 1.5em;

    &.info-alert {
      height: calc(100% - 4.5em);
      margin-top: 0;
    }

    > .online-onsite {
      position: absolute;
      left: 0;
      bottom: 0;
      width: calc(100% - 10px);
      padding: 0 5px;
      @include flex-row;
      @include flex-justify-space-between;

      > img {
        height: 1em;
      }
    }
  }

  transition-duration: 0.2s;
  &:hover {
    left: 0 !important;
    width: 100% !important;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

    > .info {
      overflow-y: auto;
    }
  }

  &.short {
    > * {
      visibility: hidden;
      display: none;
    }
    @include flex-row;
    @include flex-align-center;
    @include flex-justify-start;
    > .start,
    .end {
      visibility: visible;
      display: initial;
      position: static;
      width: fit-content;
      height: 100%;

      @include flex;
      @include flex-center;
      padding: 0 10px;

      &.end {
        padding-left: 0;
      }
    }
    > .title {
      visibility: visible;
      display: block;
      padding: 0 10px;
    }
  }
}
